@tailwind base;
@tailwind components;
@tailwind utilities;

.relative {
  position: relative;
  overflow: hidden;
}

.absolute {
  position: absolute;
}

.transition-transform {
  transition: transform 1s ease;
}

.translate-x-0 {
  transform: translateX(0%);
}

.translate-x-full {
  transform: translateX(100%);
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .nav {
  background-color: #D2E9E9;
  border-radius: 10px;
  margin-bottom: 2000px;
  margin-top: 3000px;
  max-width: 1800px;
  max-height: 1500px;
  margin: 0 auto;
  /* padding: 20px; */
  /* display: flex; */
  /* justify-content: space-between; */
/* } */ 

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .App {
  max-width: 1300px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
} */

/* nav {
  background-color: #D2E9E9;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 1000px;
  margin: 0 auto;
} */

/* ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

li.active {
  background-color: #2d8fd5;
  color: white;
}

li:hover {
  background-color: 'bg-teal-900';
}

main {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

/* li a:focus {
  background-color: teal;
} */

/* .home .for_home, .services .for_services, .contactus .for_contact_us, .aboutus .for_about_us {
  background-color: rgb(79, 240, 240);
} */

 body {
  /* margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #ffffff; 
  /* font-family: 'Times New Roman', Times, Verdana, Tahoma, sans-serif; */
  /* background-image: linear-gradient(to left, rgb(208, 201, 221), rgb(219, 213, 235)); */
} 